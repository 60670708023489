export default {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  // TODO: env variable
  //authDomain:
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  // TODO: env variable
  //storageBucket:
  // TODO: env variable
  //messagingSenderId:
  // TODO: env variable
  //appId:
};